'use strict';

$(document).ready(function(){
    $('body').addClass('i-load');
})
$(document).on('touchend click', '.js-active', function(e){

    if (!e.target.nodeName == 'A') e.preventDefault();

    var $this = $(e.target),
        $collect = $(this).find('[data-active]');

    if ($this.is('.i-icon')) {
        $collect.removeClass('is-active');
        $this.parent('[data-active]').addClass('is-active');
    }
});
/*
$('.choice-select').select2({
    tags: "true",
    placeholder: "Select an option",
    allowClear: true,
    minimumInputLength: 3,
    language: { inputTooShort: function () { return ''; } }
});
$('.choice-select-without').select2({
    tags: "true",
});
*/

$('.choice-select, .choice-select-without').styler();
$(document).on('touchend click', '.js-tab', function(e){

    e.preventDefault();

    var $this = $(this),
        $dataTab = $this.data('tab'),
        $collect = $this.parents('.js-tab-box'),
        $tabs = $collect.find('.js-tab'),
        $tabContent = $collect.find('.js-tab-content');

    $tabContent.removeClass('is-active');
    $tabs.removeClass('is-active');

    $this.addClass('is-active');
    
    $tabContent.each( function(index, val){
        if($(this).data('tab') == $dataTab) $(this).addClass('is-active');
    });

    if ($this.is('.js-tab_auto')) {
        $('#formPolicyType').removeClass('i-hidden');
    } else {
        $('#formPolicyType').addClass('i-hidden');
    }

});
// Search bar
$(document).on('click ', '.js-search-bar', function(){

    if(!$(this).is('.is-focus')) {
        navBar.open();
    }

    return false;
});

$(document).on('click ', function(e) {
    if($(e.target).closest('.js-search-bar').length==0) {
        navBar.close();
    }
});

$(document).on('click ', '.i-icon_search_close', function(e){
    navBar.close();
    e.stopPropagation();
});


function search(options){
    var elem = options.element;

    function open(){
        $(elem).addClass('is-focus');
        $(elem).find('input').focus();
    }
    function close(){
        $(elem).removeClass('is-focus');
    }

    this.open = open;
    this.close = close;
}
var navBar = new search({
    element: document.getElementById('navBar')
});
function openBox(options){
    var elem = options.element;
    var action = options.button;

    function open(){
        $(elem).addClass('is-open');
        $(action).addClass('is-focus');
    }
    function close(){
        $(action).removeClass('is-focus');
        $(elem).removeClass('is-open');
    }

    this.open = open;
    this.close = close;
}
var citySelect = new openBox({
    element: document.getElementById('selectRegionPane'),
    button: document.getElementById('selectRegion')
});

// City select
$(document).on('click ', '#selectRegion', function(){

    if(!$(this).is('.is-focus')) {
        citySelect.open();
    }

    return false;
});

$(document).on('click ', function(el) {
    if($(el.target).closest('#selectRegionPane').length==0) {
        citySelect.close();
    }
});

// Accordeon
var w_width = $(window).width();

$(window).resize(function(){
    w_width = $(window).width();

    if(w_width>767) {
        $('.is-dropdown-cover').show();
    }
   
});

$(document).on('click ', '.js-dropdown-link:not(.info__link-box)', function(){

    if(w_width<768) {
        $(this).next('.is-dropdown-cover').slideToggle();
    }

    return false;
});

$(document).on('click ', '.info__link-box.js-dropdown-link', function(){

    if(w_width<768) {
        $(this).find('.is-dropdown-cover').slideToggle();
    }
    return false;
});

$(document).on('click ', '.info__link-box.js-dropdown-link-footer .info__item-title', function(){

    if(w_width<768) {
        $(this).parent().find('.is-dropdown-cover').slideToggle();
    }
    return false;
});

$(document).on('click ', '.hamburger', function(){
 
    $(this).toggleClass('is-active');

});
// init
$(document).ready(function () {

    initModal('.js-modal', 'mfp-move-from-top');


    $(document).on('click ', '.js-modal-close', function(){
        $.magnificPopup.close();
    });


    $(document).on('click ', '.js-mobile-modal', function(){

        var itemID = $(this).attr('href');
        $(itemID).addClass('is-active');

        if(w_width>767) {

            $(itemID).show();
        }

        return false;
    });

    $(document).on('click ', '.js-slide-down', function(){

        var itemID = $(this).attr('href');
        $(itemID).slideToggle();
        
        return false;
    });


    $(document).on('click ', '.js-back-link', function(){
        $(this).parent().removeClass('is-active');

        if(w_width>999) {
            $('.recall-block:not(.recall-block_slide)').show();
            $('.recall-block_slide').hide();
            $(itemID).hide();
        }

        if(w_width>767 && w_width<1000) {
            $('.recall-block:not(.recall-block_slide)').show();
            $(itemID).hide();
        }

        if(w_width<768) {

        }
    });


});



function initModal(e, className, type) {
    $(e).magnificPopup({
        type:'inline',
        removalDelay: 250,
        fixedContentPos: true,
        fixedBgPos: true,
        showCloseBtn: false,
        callbacks: {
            open: function() {


                var w_width = $(window).width();

                if(this.currItem.el.data('modal-view') == 'view-call'){
                    $('.recall-block-phone').show();
                    $('.not-call').hide();
                } else {

                    $('.not-call').show();


                    if(w_width>767 && w_width<1000) {
                        $('.recall-block_slide').show();
                    }

                    if(w_width>767){
                        $('.recall-block-phone').hide();
                    } else {
                        $('.recall-block-phone').show();
                    }    
                }


                $('body').addClass('body_modal_show');
                $('.modal').addClass('js-slide');

                setTimeout(function() {
                    
/*
                    $(function() {      
                      //Keep track of how many swipes
                      var count=0;
                      $(".modal").swipe( {
                        swipeRight:function() {  
                            $.magnificPopup.close();
                        },
                        //Default is 75px, set to 0 for demo so any distance triggers swipe
                        threshold:200
                      });
                    });

*/
                }, 200);

            },
            beforeClose: function() {
                $('.modal').removeClass('js-slide');
                $('.hamburger').removeClass('is-active');                
            },
            close: function() {
                $('.recall-block').show();
                $('.recall-block_slide').hide();
                if(w_width<999){
                    $('.recall-block_slide').removeClass('is-active').show();
                }                 
                $('body').removeClass('body_modal_show');
            }
        }
    });
}
function spinner(options){
    var elem = options.element;
    var spinnerInput = elem.find('input');
    var spinnerBtn = elem.find('[data-btn]');
    var spinnerPlus = elem.find('[data-btn="plus"]');
    var spinnerMinus = elem.find('[data-btn="minus"]');
    var minSpinner = elem.attr('data-min');
    var maxSpinner = elem.attr('data-max');


    // Add one more
    function plus(){
        var summary = +spinnerInput.val();
        spinnerInput.val(summary + 1);

        check();
    }

    // Remove one
    function minus(){
        var summary = +spinnerInput.val();
        spinnerInput.val(summary - 1);

        check();
    }

    // Check limits of input
    function check(){
        var summary = +spinnerInput.val();

        spinnerBtn.removeClass('is-disable');

        if (summary == minSpinner || summary < minSpinner) {
            spinnerMinus.addClass('is-disable');
        } else if (summary == maxSpinner || summary > maxSpinner) {
            spinnerPlus.addClass('is-disable');
        } else {
            spinnerBtn.removeClass('is-disable');
        }

        if (summary < minSpinner) spinnerInput.val(minSpinner);
        if (summary > maxSpinner) spinnerInput.val(maxSpinner);
    }


    // Global methods
    this.plus = plus;
    this.minus = minus;


    // Events
    // Keypress check text symbol
    elem.on('keypress', 'input', function(ev) {

        ev = ev || event;

        if (ev.ctrlKey || ev.altKey || ev.metaKey) return;

        var chr = getChar(ev);

        if (chr == null) return;
        if (chr < '0' || chr > '9') {
            return false;
        }
    });

    // Keydown catch press arrow up and down
    elem.on('keydown', 'input', function(ev){

        switch (ev.keyCode) {
            case 38:
                plus();
                break;
            case 40:
                minus();
                break;
        }
    })

    // Click on plus/minus buttons
    elem.on('click input', function(e){

        var target = $(e.target).attr('data-btn');

        switch (target) {
            case 'plus':
                plus();
                break;
            case 'minus':
                minus();
                break;
        }

        check();


    });
}
var peopleQty = new spinner({
    element: $('#peopleQTY')
});
var daysQty = new spinner({
    element: $('#daysQTY')
});

// Crossbrowser function for keyCode
function getChar(event) {
    if (event.which == null) {
        if (event.keyCode < 32) return null;
        return String.fromCharCode(event.keyCode) // IE
    }

    if (event.which != 0 && event.charCode != 0) {
        if (event.which < 32) return null;
        return String.fromCharCode(event.which) // остальные
    }

    return null; // специальная клавиша
}
if ($('.js-phone').length > 0) {
    $(".js-phone").inputmask({"mask": "+7(999) 999-9999"});
    $(".js-phone").on('focus', function(event) {
      $(this)[0].setSelectionRange(0,0);
    });
}

if($('.js-policy-request-form').length) {
    $('.js-policy-request-form').parsley({
        errorClass: 'error',
        errorsWrapper: '<div class="i-error-container"></div>',
        errorTemplate: '<span class="i-error"></span>',
        trigger: 'change focusout',
        triggerAfterFailure: 'change focusout',
        requiredMessage: "Поле должно быть заполнено",
        minlengthMessage: "Поле заполнено неверно",
    }).on('form:submit', function() {
        // console.log($('.js-policy-request-form').serialize());
        // return false;
    });
}


if($('.js-call-request-form').length) {
    $('.js-call-request-form').parsley({
        errorClass: 'error',
        errorsWrapper: '<div class="i-error-container"></div>',
        errorTemplate: '<span class="i-error"></span>',
        trigger: 'change focusout',
        triggerAfterFailure: 'change focusout',
        requiredMessage: "Поле должно быть заполнено",
        minlengthMessage: "Поле заполнено неверно",
    }).on('form:submit', function() {
        // console.log($('.js-call-request-form').serialize());
        // return false;
    });
}

// временное решение, таск #38870 (статично, без логики)

$(document).on('click ', '.js-tab[data-sum]', function(e){
	var temp_sum = $(this).attr('data-sum');
    $('.mosaic-calc__summary-price_val').text(temp_sum);
});
var $whyAside = $('#whyAside');
var $whyAsideContainer = $('#whyAsideContainer');
var viewportAside = ($whyAsideContainer.height() - $whyAside.height()) / $whyAside.height();


$('.one-page__item_scroll').on('scroll', function(){

    if (this.getBoundingClientRect().top > document.getElementById('whyList').getBoundingClientRect().top - 200 ) {


        $whyAsideContainer.css({
            transform: 'translateY(' + ((document.getElementById('whyList').getBoundingClientRect().top - this.getBoundingClientRect().top - 200) * viewportAside) + 'px)'
        });
    } else if (this.getBoundingClientRect().top < document.getElementById('whyList').getBoundingClientRect().top) {
        $whyAsideContainer.css({
            transform: 'translateY(0px)'
        });
    }

})